import state from "../state";
export default function saveImage(mainCanvas) {
    const padding = 50;
    const paddedMinX = Math.max(state.getMinX() - padding, 0);
    const paddedMinY = Math.max(state.getMinY() - padding, 0);
    const paddedMaxX = Math.min(state.getMaxX() + padding, mainCanvas.width);
    const paddedMaxY = Math.min(state.getMaxY() + padding, mainCanvas.height);
    const croppedWidth = paddedMaxX - paddedMinX;
    const croppedHeight = paddedMaxY - paddedMinY;
    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    tempCanvas.width = croppedWidth;
    tempCanvas.height = croppedHeight;
    tempCtx.drawImage(mainCanvas, paddedMinX, paddedMinY, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);
    const croppedImage = tempCanvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = croppedImage;
    link.download = "board-" + Date.now() + ".png";
    link.click();
}
