export default function drawAlignmentDots(alignmentCanvas, alignmentCtx) {
    alignmentCtx.clearRect(0, 0, alignmentCanvas.width, alignmentCanvas.height);
    alignmentCtx.fillStyle = "rgba(255, 255, 255, 0.1)";
    const dotSize = 1;
    const spacing = 50;
    const startingOffset = 25;
    for (let x = startingOffset; x < alignmentCanvas.width; x += spacing) {
        for (let y = startingOffset; y < alignmentCanvas.height; y += spacing) {
            alignmentCtx.beginPath();
            alignmentCtx.arc(x, y, dotSize, 0, Math.PI * 2);
            alignmentCtx.fill();
        }
    }
}
