import state from "./state";
import "./styles.scss";
import drawSpline from "./functions/drawSpline";
import throttle from "./functions/throttle";
import saveCanvasState from "./functions/saveCanvasState";
import drawAlignmentDots from "./functions/drawAlignmentDots";
import updateBounds from "./functions/updateBounds";
import undoLastStroke from "./functions/undoLastStroke";
import saveImage from "./functions/saveImage";
import useColorPicker from "./functions/useColorPicker";
import clearBoard from "./functions/clearBoard";
document.addEventListener("DOMContentLoaded", () => {
    const mainCanvas = document.getElementById("drawingCanvas");
    const alignmentCanvas = document.getElementById("alignmentCanvas");
    if (!mainCanvas || !alignmentCanvas) {
        console.error("Canvas element not found");
        return;
    }
    const mainCtx = mainCanvas.getContext("2d", { willReadFrequently: true });
    const alignmentCtx = alignmentCanvas.getContext("2d");
    if (!mainCtx || !alignmentCtx) {
        console.error("Failed to get 2D context");
        return;
    }
    mainCanvas.width = window.innerWidth;
    mainCanvas.height = window.innerHeight;
    alignmentCanvas.width = window.innerWidth;
    alignmentCanvas.height = window.innerHeight;
    mainCtx.fillStyle = "#000";
    mainCtx.fillRect(0, 0, mainCanvas.width, mainCanvas.height);
    mainCtx.strokeStyle = "#fff";
    mainCtx.lineWidth = 3;
    mainCtx.lineCap = "round";
    mainCtx.shadowColor = mainCtx.strokeStyle;
    mainCtx.shadowBlur = 1;
    mainCtx.imageSmoothingEnabled = true;
    mainCtx.imageSmoothingQuality = "high";
    drawAlignmentDots(alignmentCanvas, alignmentCtx);
    const startDrawing = (event) => {
        state.setDrawing(true);
        const { offsetX, offsetY } = event;
        saveCanvasState(mainCanvas, mainCtx);
        state.setPoints([{ x: offsetX, y: offsetY }]); // Start new spline
        updateBounds(offsetX, offsetY);
    };
    const draw = (event) => {
        if (!state.getDrawing())
            return;
        const { offsetX, offsetY } = event;
        const points = state.getPoints();
        points.push({ x: offsetX, y: offsetY });
        state.setPoints(points);
        drawSpline(mainCtx, points);
        updateBounds(offsetX, offsetY);
    };
    const throttledDraw = throttle(draw, 16);
    const stopDrawing = () => {
        state.setDrawing(false);
        const boundaryHistory = state.getBoundaryHistory();
        boundaryHistory.push({
            minX: state.getMinX(),
            minY: state.getMinY(),
            maxX: state.getMaxX(),
            maxY: state.getMaxY(),
        });
        state.setBoundaryHistory(boundaryHistory);
    };
    // Handle mouse events
    mainCanvas.addEventListener("mousedown", (e) => {
        if (e.button === 2) {
            e.preventDefault();
            undoLastStroke(mainCanvas, mainCtx);
        }
        else if (e.button === 1) {
            e.preventDefault();
            useColorPicker(e, mainCtx);
        }
        else {
            startDrawing(e);
        }
    });
    mainCanvas.addEventListener("mousemove", (e) => {
        if (state.getDrawing()) {
            throttledDraw(e);
        }
    });
    mainCanvas.addEventListener("mouseup", () => stopDrawing());
    // Handle touch events
    mainCanvas.addEventListener("touchstart", handleTouchStart, false);
    mainCanvas.addEventListener("touchmove", handleTouchMove, false);
    mainCanvas.addEventListener("touchend", handleTouchEnd, false);
    function handleTouchStart(e) {
        e.preventDefault();
        const touch = e.touches[0];
        const mouseEvent = new MouseEvent("mousedown", {
            clientX: touch.clientX,
            clientY: touch.clientY,
        });
        mainCanvas.dispatchEvent(mouseEvent);
    }
    function handleTouchMove(e) {
        e.preventDefault();
        const touch = e.touches[0];
        const mouseEvent = new MouseEvent("mousemove", {
            clientX: touch.clientX,
            clientY: touch.clientY,
        });
        mainCanvas.dispatchEvent(mouseEvent);
    }
    function handleTouchEnd(e) {
        e.preventDefault();
        const mouseEvent = new MouseEvent("mouseup", {});
        mainCanvas.dispatchEvent(mouseEvent);
    }
    const saveBtn = document.getElementById("saveBtn");
    saveBtn.addEventListener("click", () => saveImage(mainCanvas));
    mainCanvas.addEventListener("contextmenu", (e) => {
        e.preventDefault();
    });
    const clearBtn = document.getElementById("clearBtn");
    clearBtn.addEventListener("click", () => clearBoard(mainCanvas, mainCtx));
    const undoBtn = document.getElementById("undoBtn");
    undoBtn.addEventListener("click", () => undoLastStroke(mainCanvas, mainCtx));
    const colorBtn = document.getElementById("colorBtn");
    const colorDropdown = document.getElementById("colorDropdown");
    const colorOptions = document.querySelectorAll(".colorOption");
    colorBtn.addEventListener("click", () => {
        colorDropdown.style.display =
            colorDropdown.style.display === "none" ? "block" : "none";
    });
    colorOptions.forEach((option) => {
        option.addEventListener("click", (event) => {
            const target = event.target;
            const selectedColor = target.getAttribute("data-color");
            if (selectedColor) {
                colorBtn.style.backgroundColor = selectedColor;
                mainCtx.strokeStyle = selectedColor;
                mainCtx.shadowColor = selectedColor;
                colorDropdown.style.display = "none";
            }
        });
    });
});
