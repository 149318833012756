import state from "../state";
import restorePreviousBounds from "./restorePreviousBounds";
export default function undoLastStroke(mainCanvas, mainCtx) {
    const canvasStates = state.getCanvasStates();
    if (canvasStates.length > 0) {
        const lastState = canvasStates.pop();
        if (lastState) {
            mainCtx.putImageData(lastState, 0, 0);
            restorePreviousBounds(mainCanvas);
        }
    }
}
