import state from "../state";
import resetBounds from "./resetBounds";
export default function restorePreviousBounds(mainCanvas) {
    const boundaryHistory = state.getBoundaryHistory();
    if (boundaryHistory.length > 0) {
        const lastBounds = boundaryHistory.pop();
        if (lastBounds && boundaryHistory.length > 0) {
            resetBounds(mainCanvas);
            for (const bounds of boundaryHistory) {
                state.setMinX(Math.min(state.getMinX(), bounds.minX));
                state.setMinY(Math.min(state.getMinY(), bounds.minY));
                state.setMaxX(Math.max(state.getMaxX(), bounds.maxX));
                state.setMaxY(Math.max(state.getMaxY(), bounds.maxY));
            }
        }
    }
}
