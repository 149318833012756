class State {
    constructor() {
        this.drawing = false;
        this.points = [];
        this.boundaryHistory = [];
        this.canvasStates = [];
        const mainCanvas = document.getElementById("drawingCanvas");
        this.minX = mainCanvas.width;
        this.minY = mainCanvas.height;
        this.maxX = 0;
        this.maxY = 0;
    }
    static getInstance() {
        if (!State.instance) {
            State.instance = new State();
        }
        return State.instance;
    }
    getDrawing() {
        return this.drawing;
    }
    setDrawing(value) {
        this.drawing = value;
    }
    getPoints() {
        return this.points;
    }
    setPoints(value) {
        this.points = value;
    }
    getMinX() {
        return this.minX;
    }
    setMinX(value) {
        this.minX = value;
    }
    getMinY() {
        return this.minY;
    }
    setMinY(value) {
        this.minY = value;
    }
    getMaxX() {
        return this.maxX;
    }
    setMaxX(value) {
        this.maxX = value;
    }
    getMaxY() {
        return this.maxY;
    }
    setMaxY(value) {
        this.maxY = value;
    }
    getBoundaryHistory() {
        return this.boundaryHistory;
    }
    setBoundaryHistory(value) {
        this.boundaryHistory = value;
    }
    getCanvasStates() {
        return this.canvasStates;
    }
    setCanvasStates(value) {
        this.canvasStates = value;
    }
}
const stateInstance = State.getInstance();
export default stateInstance;
