import catmullRom from "./catmullRom";
export default function drawSpline(ctx, points) {
    if (points.length < 4) {
        return;
    }
    const randomOpacity = Math.random() * 0.2 + 0.5;
    const offsetX = (Math.random() - 0.5) * 4;
    const offsetY = (Math.random() - 0.5) * 0.5;
    ctx.globalAlpha = randomOpacity; // Slightly more opaque
    ctx.shadowBlur = 0.2; // Less blur for a more defined stroke
    ctx.beginPath();
    ctx.moveTo(points[0].x + offsetX, points[0].y + offsetY);
    for (let i = 0; i < points.length - 3; i++) {
        const p0 = points[i];
        const p1 = points[i + 1];
        const p2 = points[i + 2];
        const p3 = points[i + 3];
        for (let t = 0; t <= 1; t += 0.1) {
            const x = catmullRom(p0.x, p1.x, p2.x, p3.x, t) + offsetX;
            const y = catmullRom(p0.y, p1.y, p2.y, p3.y, t) + offsetY;
            ctx.lineTo(x, y);
        }
    }
    ctx.stroke();
    ctx.globalAlpha = 1.0; // Reset alpha
    ctx.shadowBlur = 0; // Reset shadow blur
}
