export default function useColorPicker(e, mainCtx) {
    const colorPicker = document.getElementById("color-picker");
    colorPicker.style.left = `${e.clientX}px`;
    colorPicker.style.top = `${e.clientY}px`;
    colorPicker.classList.remove("hidden");
    const onColorSliceClick = (event) => {
        const target = event.target;
        const selectedColor = target.style.backgroundColor;
        mainCtx.strokeStyle = selectedColor;
        mainCtx.shadowColor = selectedColor;
        const colorBtn = document.getElementById("colorBtn");
        colorBtn.style.backgroundColor = selectedColor;
        colorPicker.classList.add("hidden");
        colorPicker.removeEventListener("click", onColorSliceClick);
    };
    colorPicker.addEventListener("click", onColorSliceClick);
    const onMouseLeave = (event) => {
        const relatedTarget = event.relatedTarget;
        if (!colorPicker.contains(relatedTarget)) {
            colorPicker.classList.add("hidden");
            colorPicker.removeEventListener("mouseleave", onMouseLeave);
            colorPicker.removeEventListener("click", onColorSliceClick);
        }
    };
    colorPicker.addEventListener("mouseleave", onMouseLeave);
}
